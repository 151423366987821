<!-- (c) 智汇考题 -->
<template>
    <div class="container">
        <nav-header :title="header_title"></nav-header>
        <div>
            <div class="info justify-center flex-row">
                <div class="pass-score">
                    {{detail.name}}
                </div>
            </div>
            <div class="sum justify-center flex-row">
                <span>本套练习共 {{chapters.length}} 个章节</span>
            </div>
            <div class="questions">
                <div class="item-question flex-col justify-center" v-for="item in chapters" :key="item.id" @click="goPracticeChapter(item)">
                    <div class="name">
                        <span>{{item.name}}</span>
                    </div>
                    <div class="num">已练习 <span class="ml-5">{{ item.userHasQuestionsCount }}/{{item.questionCount}}</span> 道题</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import NavHeader from "../../../components/nav-header.vue";

export default {
    components: {
        NavHeader
    },
    data() {
        return {
            id: this.$route.query.id,
            header_title: '练习模式',
            loading: false,
            detail: '',
            chapters: [],
            surplus: 99999,
            can_join: false,
            joinLoading: false,
        }
    },
    mounted() {
        this.getData();
    },
    computed: {
        ...mapState(["isLogin"]),
    },
    methods: {
        getData() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.$api.Exam.PracticeDetail(this.id).then((res) => {
                this.loading = false;
                this.detail = res.data.examPractice;
                this.chapters = res.data.chapters;
                this.can_join = res.data.enabledJoin;
            });
        },
        goPracticeChapter(chapter) {
            if (chapter.question_count === 0) {
                this.$message.error("无试题");
                return;
            }
            if (this.can_join === false) {
                this.$message.error("无权限参与");
                return;
            }
             this.$router.push({
                name: "AnswerLearnsPlay",
                query: {
                    practiceId: this.id,
                    chapterId: chapter.id,
                },
            });
        },
    }
}
</script>
<style lang="less" scoped>
    .justify-center{
        justify-content: center;
    }
    .flex-row  {
        flex-direction: row;
    }
    .ml-5{
        color: #3ca7fa;
    }
    .info {
        width: 100%;
        padding-top: 74px;
        .pass-score{
            height: 16px;
            font-size: 16px;
            font-weight: 500;
            color: #333;
            line-height: 16px;
        }
        .try-times{
            margin-left: 20px;
        }
    }
    .sum {
        width: 100%;
        height: 13px;
        margin-top: 30px;
        span {
            height: 13px;
            font-size: 13px;
            font-weight: 400;
            color: #666;
            line-height: 13px;
        }
    }
    .questions {
        width: 100%;
        box-sizing: border-box;
        padding: 30px 15px 100px 15px;
        .item-question {
            position: relative;
            width: 100%;
            height: 83px;
            background: #fff;
            padding: 15px;
            box-shadow: 0 2px 8px 0 hsla(0,0%,40%,.1);
            border-radius: 8px;
            margin-bottom: 20px;
            text-align: center;
            .name {
                width: 100%;
                height: 24px;
                font-size: 16px;
                font-weight: 500;
                color: #333;
                line-height: 24px;
                margin-bottom: 15px;
            }
            .num {
                font-size: 14px;
            }
        }
    }
    .flex-col {
        display: flex;
        flex-direction: column;
    }
    .footer-box{
        position: fixed;
        bottom: 0;
        width: 100%;
        float: left;
        height: auto;
        background: #fff;
        z-index: 100;
        border-top: 1px solid #e5e5e5;
        .button {
            margin: 10px 15px;
            width: auto;
            height: 50px;
            background: linear-gradient(315deg,#33baf7,#1784ed);
            box-shadow: 0 4px 8px 0 hsla(0,0%,40%,.1);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
        }
    }
</style>